
.line[data-v-290f9242] {
	display: flex;
	gap: 8px;
	margin-block-end: 8px;
}
.field[data-v-290f9242] {
	flex: 1
}
.condition[data-v-290f9242] {
	width: 100px;
}
.input[data-v-290f9242] {
	flex: 2
}
