.link-item[data-v-ce2f91c1]:last-child {
  margin-bottom: 0px !important;
}
.link-item[data-v-ce2f91c1]:first-child {
  margin-top: 18px;
}
.link-item[data-v-ce2f91c1] {
  display: flex;
  align-items: center;
  text-decoration: none !important;
  font-size: var(--text-md);
  color: var(--text-color);
  padding: 4px;
  margin-left: -4px;
  margin-bottom: 4px;
  border-radius: var(--border-radius-md);
  cursor: pointer;
}
.link-item[data-v-ce2f91c1] .widget-control .dropdown-btn {
  display: none;
}
.link-item[data-v-ce2f91c1] .widget-control .drag-handle {
  display: none;
}
.link-item[data-v-ce2f91c1] .widget-control .edit-button {
  display: none;
}
.link-item .shot-cut[data-v-ce2f91c1] {
  min-width: 0;
}
.indicator-pill[data-v-ce2f91c1] {
  margin-right: var(--margin-sm);
  height: 20px;
  padding: 3px 8px;
}
.card-icon[data-v-ce2f91c1] {
  margin: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
}
