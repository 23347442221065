.table-container[data-v-544f2304] {
  white-space: normal;
}
.img-container[data-v-544f2304] {
  margin-bottom: 8px;
  overflow-x: auto;
  white-space: nowrap;
}
.img-item[data-v-544f2304] {
  display: inline-flex;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 15px #666;
}
.img[data-v-544f2304] {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
