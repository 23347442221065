.directory-tree[data-v-9cc97e97] {
  height: 100%;
  overflow-y: auto;
  background: transparent;
}
.custom-tree-node[data-v-9cc97e97] {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node .homeContainer[data-v-9cc97e97] {
  opacity: 0;
}
.custom-tree-node .homeContainer.isHome[data-v-9cc97e97] {
  opacity: 1;
}
.custom-tree-node .favoriteContainer[data-v-9cc97e97] {
  opacity: 0;
}
.custom-tree-node .favoriteContainer.isFavorite[data-v-9cc97e97] {
  opacity: 1;
}
.custom-tree-node .isHome[data-v-9cc97e97] {
  color: #1f89e5;
}
.custom-tree-node .isFavorite[data-v-9cc97e97] {
  color: #1f89e5;
}
.custom-tree-node:hover .homeContainer[data-v-9cc97e97] {
  opacity: 1;
}
.custom-tree-node:hover .favoriteContainer[data-v-9cc97e97] {
  opacity: 1;
}
[data-v-9cc97e97] .el-tree-node__label {
  flex: 1;
}
