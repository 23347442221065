.timeLineItem[data-v-dd8f7dd7] {
  position: relative;
  padding-left: 32px;
}
.timeLineItem .leftTitle[data-v-dd8f7dd7] {
  position: absolute;
  transform: translateX(calc(-100% - 32px));
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
}
.timeLineItem .timeLineDot[data-v-dd8f7dd7] {
  position: absolute;
  top: 7px;
  left: 7px;
  width: 10px;
  height: 10px;
  background-color: #1890ff;
  border-radius: 9px;
}
.timeLineItem .timeLineDot[data-v-dd8f7dd7]::before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 6px;
  content: '';
}
.timeLineItem .vline[data-v-dd8f7dd7] {
  position: absolute;
  height: 100%;
  top: 17px;
  bottom: 0;
  left: 11px;
  width: 2px;
  background-color: #f5f5f5;
}
.timeLineItem .timeName[data-v-dd8f7dd7] {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}
.timeLineItem .placeholder[data-v-dd8f7dd7] {
  height: 24px;
}
