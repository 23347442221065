
.file-preview {
	display: flex;
	align-items: center;
	padding: 0.75rem;
	border: 1px solid transparent;
}
.file-preview + .file-preview {
	border-top-color: var(--border-color);
}
.file-preview:hover {
	background-color: var(--bg-color);
	border-color: var(--dark-border-color);
	border-radius: var(--border-radius);
}
.file-preview:hover + .file-preview {
	border-top-color: transparent;
}
.file-icon {
	border-radius: var(--border-radius);
	width: 2.625rem;
	height: 2.625rem;
	overflow: hidden;
	margin-right: var(--margin-md);
	flex-shrink: 0;
}
.file-icon img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.file-icon .fallback {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
}
.file-name {
	font-size: var(--text-base);
	font-weight: var(--text-bold);
	color: var(--text-color);
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.file-size {
	font-size: var(--text-sm);
	color: var(--text-light);
}
.file-actions {
	width: 3rem;
	flex-shrink: 0;
	margin-left: auto;
	text-align: center;
}
.file-actions .btn {
	padding: var(--padding-xs);
	box-shadow: none;
}
.file-action-buttons {
	display: flex;
	justify-content: flex-end;
}
.muted {
	opacity: 0.5;
	transition: 0.3s;
}
.muted:hover {
	opacity: 1;
}
.frappe-checkbox {
	font-size: var(--text-sm);
	color: var(--text-light);
	display: flex;
	align-items: center;
	padding-top: 0.25rem;
}
.config-area {
	gap: 0.5rem;
}
.file-error {
	font-size: var(--text-sm);
	font-weight: var(--text-bold);
}
.file-detail {
	flex: 1;
}
.input-container {
	display: flex;
}
.file-name-input {
	width: 100%;
}
.file-ext {
	margin-left: 5px;
}
