
img[data-v-64939dfe] {
	display: block;
	max-width: 100%;
	max-height: 600px;
}
.image-cropper-actions[data-v-64939dfe] {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: var(--margin-md);
}
