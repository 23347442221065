.icon-content[data-v-f254d0cd] {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  max-height: 210px;
}
.icon-content .icon-wrapper[data-v-f254d0cd] {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
