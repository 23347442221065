.item-btn[data-v-491134f2] {
  display: flex;
  margin-top: var(--margin-md);
  justify-content: space-between;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--btn-shadow);
  font-size: var(--text-md);
  padding: 4px 8px;
}
.item[data-v-491134f2] {
  display: flex;
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-sm);
  clear: both;
  font-weight: 400;
  color: var(--text-color);
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: var(--text-md);
}
