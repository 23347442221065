.container[data-v-fc97064b] {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}
[data-v-fc97064b] .g6-component-toolbar path {
  fill: #000;
}
[data-v-fc97064b] .g6-component-toolbar [code=redo] {
  display: none;
}
[data-v-fc97064b] .g6-component-toolbar [code=undo] {
  display: none;
}
[data-v-fc97064b] .g6-component-toolbar .graph-view-tooltip-content {
  position: relative;
}
[data-v-fc97064b] .g6-component-toolbar .graph-view-tooltip-content .tooltiptext {
  visibility: hidden;
  width: 60px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
  transition: opacity 0.3s;
}
[data-v-fc97064b] .g6-component-toolbar .graph-view-tooltip-content .tooltiptext::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}
[data-v-fc97064b] .g6-component-toolbar .graph-view-tooltip-content:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
