
.ViewPage-sidebar[data-v-a65b2c9d] {
	display: none;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
	font-size: var(--text-md);
	padding-right: 30px;
}
.ViewPage-sidebar.ViewPage-sidebar-open[data-v-a65b2c9d] {
	display: block;
}
@media (max-width: 991px) {
.ViewPage-sidebar[data-v-a65b2c9d] {
		display: block;
		flex: unset;
		max-width: unset;
}
}
.ViewPage-sidebar-close[data-v-a65b2c9d] {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	opacity: 0.3;
	background: #000;
	z-index: 1041;
	height: 100%;
	width: 100%;
	transition: transform .5s;
}
@media (max-width: 991px) {
.ViewPage-sidebar-main[data-v-a65b2c9d] {
		margin-top: 0 !important;
		position: fixed;
		background: var(--fg-color);
		top: 0;
		left: 0;
		transform: translateX(-110%);
		transition: transform .5s;
		z-index: 9999;
		box-shadow: var(--shadow-base);
		height: 100%;
		width: 40%;
		display: block !important;
		transition: transform 200ms ease-in-out;
		padding: var(--padding-md);
}
.ViewPage-sidebar-open .ViewPage-sidebar-close[data-v-a65b2c9d] {
		display: block;
}
.ViewPage-sidebar-open .ViewPage-sidebar-main[data-v-a65b2c9d] {
		transform: translateX(0);
		overflow-y: auto;
}
}
@media (max-width: 767px) {
.ViewPage-sidebar-main[data-v-a65b2c9d] {
		width: 60%;
}
}
