.root[data-v-915f9c5e] {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.root[data-v-915f9c5e] > :first-child {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
