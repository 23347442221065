.filterContainer[data-v-2e7925c1] {
  display: flex;
  align-items: center;
  width: 100%;
}
.at[data-v-2e7925c1] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-inline-end: 8px;
}
