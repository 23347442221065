.detail-container[data-v-aae4b606] {
  position: relative;
  border-radius: 8px;
  margin-right: 8px;
  padding: 8px;
  padding-top: 0;
}
.detail-container[data-v-aae4b606]:hover {
  background: #f0f2f5;
}
.detail-container:hover .to_detail[data-v-aae4b606] {
  opacity: 1;
}
.detail-container .to_detail[data-v-aae4b606] {
  position: absolute;
  right: 10px;
  opacity: 0;
}
.detail-container .detail-item[data-v-aae4b606] {
  margin: 4px 0;
}
.detail-container .detail-item .label[data-v-aae4b606] {
  margin-right: 8px;
  font-weight: bolder;
}
.detail-container .detail-item .value[data-v-aae4b606] {
  flex: 1;
  white-space: pre-wrap;
}
