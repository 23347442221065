.main[data-v-9b0d3bd4] {
  overflow: auto;
  flex: 0 var(--9b0d3bd4-size);
}
.resize[data-v-9b0d3bd4] {
  flex: 0 1px;
  z-index: 2;
  box-sizing: content-box;
  background: #d9d9d9;
  background-clip: padding-box;
  border-color: transparent;
  border-style: solid;
  margin: 0 -5px;
  border-width: 0 5px;
  cursor: col-resize;
}
.resize[data-v-9b0d3bd4]:hover {
  border-color: #0000001a;
  transition: border-color 2s ease;
}
