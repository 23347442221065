
.file-browser-list {
	height: 300px;
	overflow: hidden;
	margin-top: 10px;
}
.file-filter {
	padding: 3px;
}
.tree {
	overflow: auto;
	height: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 4rem;
}
