.tag[data-v-31dd1a61] {
  display: flex;
  justify-content: space-between;
  margin-block: 8px;
}
.input[data-v-31dd1a61] {
  display: block;
  inline-size: 100%;
  block-size: calc(1.5em + 0.75rem + 2px);
  padding-block: 0.375rem;
  padding-inline: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
  border-radius: var(--border-radius);
  box-shadow: none;
  transition: none;
  height: 26px;
  background: var(--control-bg-on-gray);
  border: none;
  font-size: var(--text-md);
  position: relative;
}
.input[data-v-31dd1a61]:focus {
  border-color: var(--gray-500);
  outline: 0;
  box-shadow: 0 0 0 2px #68717840;
}
.label[data-v-31dd1a61] {
  margin-bottom: var(--margin-sm);
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--text-muted);
  letter-spacing: 0.04rem;
  font-size: var(--text-sm);
}
.tip[data-v-31dd1a61] {
  color: var(--text-muted) !important;
  font-weight: 400;
  font-size: var(--text-sm);
  margin-top: 4px;
  margin-bottom: 8px;
  line-height: 1.6;
}
.checkbox *[data-v-31dd1a61] {
  font-size: var(--text-sm) !important;
}
.checkbox input[data-v-31dd1a61] {
  height: 0;
  width: 0 !important;
  margin: 0 !important;
}
