.global-layout[data-v-31db742a] {
  height: calc(100vh - var(--navbar-height));
  margin: auto;
  width: 100%;
  padding-inline: 15px;
  display: flex;
  flex-direction: column;
}
.add[data-v-31db742a] {
  display: block;
  margin-inline: 0.5em;
}
.header[data-v-31db742a] {
  display: flex;
  align-items: center;
}
.header .tabs[data-v-31db742a] {
  flex: 1;
  width: 1px;
}
.el-tabs__item {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}
.body[data-v-31db742a] {
  flex: 1;
}
