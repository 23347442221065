
.ViewPageTitleText[data-v-d25a2f73] {
	flex: 1;
}
.ViewPageTitleText-flex[data-v-d25a2f73] {
	display: flex;
}
.ViewPageTitleText-title[data-v-d25a2f73] {
	cursor: pointer;
	margin-bottom: 0px;
	margin-right: var(--margin-sm);
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
}
.ViewPageTitleText-indicator[data-v-d25a2f73] {
	margin-top: 2px;
	padding: 3px 10px;
	font-size: var(--text-sm);
	border-radius: var(--border-radius);
	font-weight: 500;
	height: 24px;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
}
.ViewPageTitleText-indicator[data-v-d25a2f73]::before {
	content: "";
	display: inline-table;
	height: 4px;
	width: 4px;
	border-radius: 50%;
	margin-right: 6px;
}
.ViewPageTitleText-subtitle[data-v-d25a2f73] {
	font-size: var(--text-sm);

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	vertical-align: middle;

	color: var(--text-muted) !important;
}
