
.ViewPageSidebarToggleBtn[data-v-81702056] {
	display: flex;
	margin-right: var(--margin-sm);
	cursor: pointer;
	--icon-stroke: var(--gray-500);
}
.ViewPageSidebarToggleBtn:hover .ViewPageSidebarToggleBtn-placeholder[data-v-81702056] {
	display: none;
}
.ViewPageSidebarToggleBtn-icon[data-v-81702056] {
	display: none;
}
.ViewPageSidebarToggleBtn:hover .ViewPageSidebarToggleBtn-icon[data-v-81702056] {
	display: block;
}
