
.file-upload-area {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed var(--dark-border-color);
	border-radius: var(--border-radius);
	cursor: pointer;
	background-color: var(--bg-color);
	padding-block: 10px;
}
.file-upload-area > div {
	inline-size: 100%;
}
.file-upload-area form {
	inline-size: 100%;
	display: flex;
	flex-direction: column;
	padding-block: 5px;
	padding-inline: 20px;
}
.file-upload-area textarea {
	resize: vertical;
	resize: block;
	min-block-size: 100px;
}
.btn-file-upload {
	background-color: transparent;
	border: none;
	box-shadow: none;
	font-size: var(--text-xs);
}
