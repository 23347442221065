.small[data-v-e0816d38] {
  padding: 0!important;
}
.small .link[data-v-e0816d38] {
  font-size: var(--text-md);
  color: var(--text-color);
  font-weight: 400;
  line-height: 1.5;
}
.link[data-v-e0816d38] {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
  max-width: 100%;
  align-items: center;
}
.link span[data-v-e0816d38] {
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.link[data-v-e0816d38]:hover {
  text-decoration: underline !important;
  color: var(--blue-500);
}
.editing .link[data-v-e0816d38] {
  font-size: 16px;
}
