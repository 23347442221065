.sider[data-v-884f9e7b] {
  display: flex;
  flex-direction: row;
  grid-area: sider;
  inline-size: var(--mainView-sider);
}
.sider.hidden[data-v-884f9e7b] {
  display: none;
}
@media screen and (max-width: 992px) {
.sider[data-v-884f9e7b] {
    background: #0003;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1020;
    transition: 0.2s margin-left, 0.2s width;
}
.sider.hidden[data-v-884f9e7b] {
    display: flex;
    margin-left: calc(-1 * var(--mainView-sider2));
    width: var(--mainView-sider2);
}
}
.context[data-v-884f9e7b] {
  overflow: auto;
  flex: 1;
  padding-inline-end: var(--padding-md);
  padding-inline-start: 2px;
}
@media screen and (max-width: 992px) {
.context[data-v-884f9e7b] {
    background: #FFFFFF;
    flex: 0 var(--mainView-sider2);
    padding: var(--padding-md);
}
}
.resize[data-v-884f9e7b] {
  z-index: 7;
  box-sizing: content-box;
  width: 1px;
  margin: 0 -5px;
  background: #d9d9d9;
  background-clip: padding-box;
  border: transparent solid;
  border-width: 0 5px;
  cursor: col-resize;
  margin-block: 5px;
}
.resize[data-v-884f9e7b]:hover {
  border-color: #0000001a;
  transition: border-color 2s ease;
}
.hidden .resize[data-v-884f9e7b] {
  display: none;
}
@media screen and (max-width: 992px) {
.resize[data-v-884f9e7b] {
    display: block;
    margin-block: 0;
}
.hidden .resize[data-v-884f9e7b] {
    display: block;
}
}
