.toggle-btn[data-v-e5c2b9e3] {
  display: flex;
  margin-right: var(--margin-sm);
  cursor: pointer;
  --icon-stroke: var(--gray-500);
}
.toggle-btn .icon_status[data-v-e5c2b9e3] {
  display: none;
}
.toggle-btn:hover .placeholder[data-v-e5c2b9e3] {
  display: none;
}
.toggle-btn:hover .icon_status[data-v-e5c2b9e3] {
  display: block;
}
