.header[data-v-99cc2424] {
  display: flex;
}
.header > *[data-v-99cc2424] {
  flex: 1;
}
.header[data-v-99cc2424] > :first-child {
  width: 100px;
  flex: unset;
}
.global-view-drawer .el-drawer__body {
  display: flex;
  overflow: hidden;
  padding: 0;
}
.menu-form[data-v-99cc2424] {
  flex: 1;
  overflow: auto;
  padding: 20px;
}
.menu[data-v-99cc2424] {
  width: 200px;
  overflow: auto;
  border-right: solid 1px var(--el-menu-border-color);
  background-color: var(--el-menu-bg-color);
}
.menu[data-v-99cc2424] .el-menu {
  border-right: none;
  background-color: none;
}
