.header[data-v-adfa3e5e] {
  top: 0;
  bottom: 0;
  z-index: 3;
  position: sticky !important;
}
.main[data-v-adfa3e5e] {
  overflow: scroll;
}
.resizer[data-v-adfa3e5e] {
  position: relative;
  pointer-events: all;
  inset-block: 0;
  inline-size: 1px;
  margin-inline: -3px;
  border: solid transparent;
  border-block-width: 0px;
  border-inline-start-width: 3px;
  border-inline-end-width: 5px;
  border-radius: 4px;
  transition: border-color 0.3s;
  box-sizing: content-box;
  background-color: #d7d7d7;
  background-clip: padding-box;
  cursor: col-resize;
}
.resizer[data-v-adfa3e5e]:hover {
  border-color: #e7e7e7;
}
.gantt[data-v-adfa3e5e] {
  overflow: scroll;
  flex: 1;
}
.root[data-v-adfa3e5e] {
  display: flex;
  flex-direction: row;
  width: 100%;
  --nyloong-table-header-row-height: 32px;
  --nyloong-table-header-background-color: #f5f7f7;
  --nyloong-table-row-border-color: rgba(189, 195, 199, 0.58);
  font-size: 12px;
}
.root[data-v-adfa3e5e] > :last-child {
  flex: 1;
}
.nyloong-table-header:not(.nyloong-table-gantt-header, .nyloong-table-checkable) {
  padding-inline: 12px;
}
.nyloong-table-cell:not(.nyloong-table-gantt-lines, .nyloong-table-checkable) {
  padding-inline: 12px;
}
