.card-config[data-v-5c36df6b] {
  max-height: calc(100vh - 260px);
  overflow: auto;
}
.card-config .config-table[data-v-5c36df6b],
.card-config[data-v-5c36df6b] .el-scrollbar__wrap,
.card-config[data-v-5c36df6b] .el-scrollbar,
.card-config[data-v-5c36df6b] .el-table__body-wrapper {
  overflow: visible;
}
.card-config[data-v-5c36df6b] .el-scrollbar__bar {
  display: none;
}
.card-config[data-v-5c36df6b] .el-table .el-table__cell {
  z-index: auto;
}
.card-config[data-v-5c36df6b] .el-checkbox__original {
  width: 0!important;
  height: 0;
  border: 0;
  margin: 0!important;
  padding: 0;
}
.btn[data-v-5c36df6b] {
  padding: 0;
}
.form-content[data-v-5c36df6b] {
  margin: 1rem;
}
.add-row-btn[data-v-5c36df6b] {
  margin-top: 8px;
}
