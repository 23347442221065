@property --nyloong-table-width {
  syntax: '<length>';
  inherits: true;
  initial-value: 100px;
}
@property --nyloong-table-fixed-start-width {
  syntax: '<length>';
  inherits: true;
  initial-value: -100px;
}
@property --nyloong-table-header-row-height {
  syntax: '<length>';
  inherits: true;
  initial-value: 24px;
}
@property --nyloong-table-header-background-color {
  syntax: '<color>';
  inherits: true;
  initial-value: #FFF;
}
@property --nyloong-table-row-height {
  syntax: '<length>';
  inherits: true;
  initial-value: 24px;
}
@property --nyloong-table-row-visible {
  syntax: '<integer>';
  inherits: true;
  initial-value: 0;
}
@property --nyloong-table-row-border-color {
  syntax: '<color>';
  inherits: true;
  initial-value: #CCC;
}
@property --nyloong-table-inline-offset {
  syntax: '<length>';
  inherits: true;
  initial-value: 0px;
}
.nyloong-table {
  box-sizing: border-box;
}
.nyloong-table * {
  box-sizing: border-box;
}
.nyloong-table-headers {
  --nyloong-table-header-row-height: var(--nyloong-table-header-row-height, 24px);
  line-height: var(--nyloong-table-header-row-height);
  display: flex;
  flex-direction: row;
  position: relative;
  block-size: var(--nyloong-table-header-row-height);
  inline-size: var(--nyloong-table-width);
}
.nyloong-table-fixed-line {
  z-index: 1;
  content: '';
  position: sticky;
  inset-block: 0;
  inset-inline-start: calc(var(--nyloong-table-inline-offset, 0px) + var(--nyloong-table-fixed-start-width));
}
.nyloong-table-fixed-line::before {
  content: '';
  position: absolute;
  inset-block: 0;
  box-shadow: 0 0 5px #666;
  inline-size: 1px;
}
.nyloong-table-header {
  position: absolute;
  inset-block: 0;
  block-size: 100%;
  box-sizing: border-box;
  background-color: var(--nyloong-table-header-background-color, #fff);
  box-shadow: inset 0 -1px #ccc;
  inset-inline-start: var(--nyloong-table-column-start);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nyloong-table-header-fixed {
  position: sticky;
  z-index: 1;
  inset-inline-start: calc(var(--nyloong-table-inline-offset, 0px) + var(--nyloong-table-column-start));
}
.nyloong-table-resize {
  position: absolute;
  inline-size: 0px;
  block-size: 100%;
  inset-inline-start: var(--nyloong-table-column-start);
}
.nyloong-table-resize::before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  inset-block: 0;
  margin-inline: -5px;
  inline-size: 1px;
  border: 4px solid transparent;
  border-block-width: 0px;
  border-radius: 4px;
  box-sizing: content-box;
  background-color: #d7d7d7;
  background-clip: padding-box;
  margin-block: 5px;
}
.nyloong-table-resize-fixed {
  position: sticky;
  inset-inline-start: calc(var(--nyloong-table-inline-offset, 0px) + var(--nyloong-table-column-start));
  z-index: 2;
}
.nyloong-table-resize-resizable {
  cursor: col-resize;
  transition: border-color 0.3s;
}
.nyloong-table-resize-resizable:hover {
  border-color: #e7e7e7;
}
.nyloong-table-body {
  position: relative;
  inline-size: var(--nyloong-table-width);
  block-size: calc(var(--nyloong-table-row-visible) * var(--nyloong-table-row-height, 24px));
}
.nyloong-table-customize {
  position: absolute;
  box-sizing: border-box;
  block-size: 100%;
  pointer-events: none;
  inset-inline-start: var(--nyloong-table-column-start);
  inset-block-start: 0;
  z-index: 2;
}
.nyloong-table-customize-fixed {
  position: sticky;
  inset-inline-start: calc(var(--nyloong-table-inline-offset, 0px) + var(--nyloong-table-column-start));
  z-index: 2;
}
.nyloong-table-customize > * {
  pointer-events: all;
}
.nyloong-table-main {
  z-index: 1;
  line-height: var(--nyloong-table-row-height, 24px);
}
.nyloong-table-row {
  position: absolute;
  inline-size: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.nyloong-table-cell {
  position: absolute;
  block-size: 100%;
  box-shadow: inset 0 -1px var(--nyloong-table-row-border-color, #ccc);
  background-color: #ffffff;
  inset-inline-start: var(--nyloong-table-column-start);
}
.nyloong-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nyloong-table-cell-fixed {
  position: sticky;
  z-index: 2;
  inset-inline-start: calc(var(--nyloong-table-inline-offset, 0px) + var(--nyloong-table-column-start));
}
.nyloong-table-row:hover .nyloong-table-cell {
  background-color: #ecf0f1;
}
.nyloong-table-row-hover .nyloong-table-cell {
  background-color: #ecf0f1;
}
.nyloong-table-row-selected .nyloong-table-cell,
.nyloong-table-row-checked .nyloong-table-cell {
  background-color: #b6dff8;
}
.nyloong-table-row-hover.nyloong-table-row-selected .nyloong-table-cell,
.nyloong-table-row-hover.nyloong-table-row-checked .nyloong-table-cell,
.nyloong-table-row-selected:hover .nyloong-table-cell,
.nyloong-table-row-checked:hover .nyloong-table-cell {
  background-color: #abd6ef;
}
.nyloong-table-checkable {
  display: flex;
}
.nyloong-table-checkable > input[type='checkbox']:first-child {
  margin-inline: 8px;
  margin-block: auto;
}
.nyloong-table-checkable > :last-child {
  flex: 1;
  overflow: auto;
}
.nyloong-table-tree {
  display: flex;
  flex-direction: row;
}
.nyloong-table-tree-collapser {
  block-size: 20px;
  inline-size: 20px;
  margin-inline-start: calc(var(--nyloong-table-tree-level) * 20px);
  margin-block: auto;
}
.nyloong-table-tree-collapser + div {
  position: relative;
  flex: 1;
}
.nyloong-table-cell-ellipsis .nyloong-table-tree-collapser + div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nyloong-table-tree-collapsible .nyloong-table-tree-collapser {
  position: relative;
  cursor: pointer;
  transform: rotate(90deg);
  transition: transform 0.2s;
}
.nyloong-table-tree-collapsible .nyloong-table-tree-collapser::after {
  content: '';
  border: 1px solid transparent;
  inline-size: 6px;
  block-size: 6px;
  border-block-end-color: currentColor;
  border-inline-end-color: currentColor;
  transform-origin: center;
  position: absolute;
  transform: rotate(-45deg);
  inset: 0;
  margin: auto;
  margin-inline-start: 4px;
}
.nyloong-table-tree-collapsible.nyloong-table-tree-collapsed .nyloong-table-tree-collapser {
  transform: none;
}
.nyloong-table-gantt-header {
  display: flex;
  flex-direction: column;
  line-height: calc(var(--nyloong-table-header-row-height) / var(--nyloong-table-gantt-header-lines));
  font-size: 12px;
}
.nyloong-table-gantt-header > div {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.nyloong-table-gantt-header > div > span {
  position: relative;
  overflow: hidden;
  text-align: center;
  inline-size: calc(var(--nyloong-table-gantt-header-size) * var(--nyloong-table-gantt-day-width));
}
.nyloong-table-gantt-header > div > span::after {
  content: '';
  inset-inline-end: 0;
  position: absolute;
  width: 1px;
  inset-block: 0;
  background: #d7d7d7;
  margin-block: 5px;
}
.nyloong-table-customize .nyloong-table-gantt-today {
  position: absolute;
  block-size: 100%;
  inline-size: 2px;
  background: #1890ff;
  pointer-events: none;
  margin-inline-start: calc(var(--nyloong-table-gantt-start) * var(--nyloong-table-gantt-day-width));
}
.nyloong-table-gantt-cell {
  background-image: var(--nyloong-table-gantt-background);
  background-size: 100%;
  background-color: #fff;
  background-repeat: repeat;
}
.nyloong-table-gantt-cell::before {
  content: '';
  inset-inline-start: 0;
  position: absolute;
  width: 1px;
  inset-block: 0;
  background: #d7d7d7;
}
.nyloong-table-gantt-dot {
  position: absolute;
  font-size: var(--nyloong-table-gantt-dot-size, 1em);
  inset-inline-start: calc((var(--nyloong-table-gantt-start) + var(--nyloong-table-gantt-dot-begin)) * var(--nyloong-table-gantt-day-width));
  inset-block-start: var(--nyloong-table-gantt-dot-position);
  transform: translate(-50%, -50%);
}
.nyloong-table-gantt-line {
  position: absolute;
  inset-inline-start: calc((var(--nyloong-table-gantt-start) + var(--nyloong-table-gantt-line-begin)) * var(--nyloong-table-gantt-day-width));
  inline-size: calc(var(--nyloong-table-gantt-line-size) * var(--nyloong-table-gantt-day-width));
  inset-block-start: var(--nyloong-table-gantt-line-position);
  block-size: var(--nyloong-table-gantt-line-weight);
  border: 1px #aaa solid;
}
