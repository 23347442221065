.label[data-v-683e89e1] {
  margin-bottom: var(--margin-sm);
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--text-muted);
  letter-spacing: 0.04rem;
  font-size: var(--text-sm);
}
.end[data-v-683e89e1] {
  margin-block-end: 30px;
}
