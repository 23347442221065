.filterContainer[data-v-f8cbf51a] {
  display: flex;
  align-items: center;
}
.filter[data-v-f8cbf51a] {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
}
.at[data-v-f8cbf51a] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-inline-end: 8px;
}
.root[data-v-f8cbf51a] {
  width: 100%;
}
.filter-area .filter-box .awesomplete ul[role=listbox] li {
  max-width: unset;
}
