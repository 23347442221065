
.ViewPage-container[data-v-badf3ecb] {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
.ViewPage-container[data-v-badf3ecb] {
		max-width: 540px;
}
}
@media (min-width: 768px) {
.ViewPage-container[data-v-badf3ecb] {
		max-width: 840px;
}
}
@media (min-width: 992px) {
.ViewPage-container[data-v-badf3ecb] {
		max-width: 1090px;
}
}
@media (min-width: 1200px) {
.ViewPage-container[data-v-badf3ecb] {
		max-width: 1290px;
}
}
.ViewPage-clearfix[data-v-badf3ecb]::after {
	display: block;
	clear: both;
	content: "";
}
.ViewPage-row[data-v-badf3ecb] {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}
@media (max-width: 991px) {
.ViewPage-row[data-v-badf3ecb] {
		position: relative;
}
}
.ViewPage-wrapper[data-v-badf3ecb] {
	margin-bottom: var(--page-bottom-margin);
	flex-basis: 0;
	flex-grow: 1;
	min-width: 0;
	max-width: 100%;
}
.ViewPage-card[data-v-badf3ecb] {
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	padding: var(--padding-sm);
	background-color: var(--card-bg);
}
@media (max-width: 767px) {
.ViewPage-card[data-v-badf3ecb] {
		border-left-color: transparent !important;
		border-right-color: transparent !important;
}
}
.ViewPage-card[data-v-badf3ecb] {
	overflow: hidden;
	border-radius: var(--border-radius-md);
	box-shadow: var(--card-shadow);
	padding: 0px;
	background-color: var(--card-bg);
}
.ViewPage-main[data-v-badf3ecb] {
	padding: 0 var(--padding-xs);
}
.ViewPage-footer[data-v-badf3ecb] {
	display: block;
}
