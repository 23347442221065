.sidler-tab[data-v-5da780de] {
  height: calc(100vh - 2*var(--padding-md) - var(--navbar-height) - 75px);
  display: flex;
  flex-direction: column;
}
.sidler-tab[data-v-5da780de] .el-tabs__content {
  height: 100%;
}
.sidler-tab .directory[data-v-5da780de] {
  height: 100%;
}
.sidler-tab[data-v-5da780de] .el-tabs__nav-wrap {
  height: 46px;
}
