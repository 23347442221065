.timeline-container[data-v-3cdf77ce] {
  height: 100%;
  overflow-y: scroll;
}
.manageContainer[data-v-3cdf77ce] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.manageContainer .manageContent.noBorder[data-v-3cdf77ce] {
  border-left: none;
}
.timeLine[data-v-3cdf77ce] {
  display: block;
}
.left-margin[data-v-3cdf77ce] {
  margin-left: var(--3cdf77ce-leftWidth);
}
