
.icon[data-v-aafa2c3b] {
	/* v-bind是Vue3才支持的功能，可以将CSS的值与js的值绑定 */
	width: var(--aafa2c3b-props\.size);
	height: var(--aafa2c3b-props\.size);
	position: relative;
	fill: currentColor;
	/* width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden; */
}
