
.input[data-v-02aad357] {
	width: 160px;
}
.limit[data-v-02aad357] {
	margin-inline: 8px;
}
.pagination[data-v-02aad357] {
	position: absolute;
	right: 20px;
	bottom: 20px;

	display: flex;
	flex-direction: column;
}
