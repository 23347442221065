.root[data-v-d4ad78a0] {
  width: 100%;
  height: 100%;
  padding: 8px;
  position: relative;
}
.ag[data-v-d4ad78a0] {
  width: 100%;
  height: 100%;
}
.ag-cell-wrapper {
  height: 100%;
}
.ag-group-value {
  flex: 1;
  height: 100%;
}
.ag-full-width-container {
  flex: unset;
}
