
.ViewPageMenuBtn-item[data-v-2b54c234]:hover,
.ViewPageMenuBtn-item[data-v-2b54c234]:focus {
	color: var(--text-color);
	text-decoration: none;
	background-color: var(--fg-hover-color);
}
.ViewPageMenuBtn-item[data-v-2b54c234] {
	border-radius: 4px;
	display: block;
	width: 100%;
	padding: var(--padding-sm) var(--padding-sm);
	clear: both;
	font-weight: 400;
	color: var(--text-color);
	text-align: inherit;
	white-space: nowrap;
	background-color: transparent;
	border: 0;
}
.ViewPageMenuBtn-label[data-v-2b54c234] {
	margin-right: var(--margin-md);
}
