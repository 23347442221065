.pagination[data-v-4cf468c0] {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ag[data-v-4cf468c0] {
  margin-block: 8px;
}
.ag-group-value {
  flex: 1;
}
.ag-full-width-container {
  flex: unset;
}
