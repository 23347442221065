.img-item[data-v-a0cdcc3a] {
  display: inline-flex;
  width: 120px;
  height: 152px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 4px;
}
.img-item[data-v-a0cdcc3a]:hover {
  background-color: #f0f6ff;
}
.img-item:hover .cursor-area .check[data-v-a0cdcc3a] {
  opacity: 1;
}
.img-item:hover .cursor-area .menu[data-v-a0cdcc3a] {
  opacity: 1;
}
.img-item.isSelected[data-v-a0cdcc3a] {
  background-color: #f0f6ff;
  border: 1px solid #1890ff;
}
.img-item.isSelected .cursor-area .check[data-v-a0cdcc3a] {
  opacity: 1;
  background-color: #1890ff;
}
.img-item .icon-container[data-v-a0cdcc3a] {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.img-item .cursor-area[data-v-a0cdcc3a] {
  position: relative;
  cursor: pointer;
  margin: auto;
}
.img-item .cursor-area .check[data-v-a0cdcc3a] {
  font-size: 30px;
  padding: 5px;
  position: absolute;
  background-color: #91d5ff;
  border-radius: 15px;
  color: #fff;
  left: -10px;
  top: -10px;
  opacity: 0;
  z-index: 1;
}
.img-item .cursor-area .check[data-v-a0cdcc3a]:hover {
  background-color: #1890ff;
}
.img-item .cursor-area .menu[data-v-a0cdcc3a] {
  font-size: 30px;
  padding: 5px;
  position: absolute;
  background-color: #91d5ff;
  border-radius: 15px;
  color: #fff;
  right: -15px;
  top: -10px;
  opacity: 0;
  z-index: 1;
}
.img-item .cursor-area .menu[data-v-a0cdcc3a]:hover {
  background-color: #1890ff;
}
.img-item .cursor-area .img[data-v-a0cdcc3a] {
  width: 84px;
  height: 84px;
  object-fit: cover;
}
.img-item .file-title[data-v-a0cdcc3a] {
  width: 100%;
  padding: 0 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 8px;
}
.dropdown[data-v-a0cdcc3a] {
  position: absolute;
  right: 0px;
}
.guigu-file-view_dropdown[data-v-a0cdcc3a] .delete {
  color: #f00 !important;
}
.private[data-v-a0cdcc3a] {
  position: absolute;
  bottom: 0px;
  right: 0;
  color: #fff;
  background: orange;
  border-radius: 8px;
  width: 31px;
  text-align: center;
  font-size: 12px;
}
